import React from 'react';
import './../App.css';
import ButtonMain from './ButtonMain.js';
import Questions from './Questions.js';
import check from '../assets/check.png';
import Analytics from './AnalyticsTracking.js';
// import 'react-input-checkbox/lib/react-input-checkbox.min.css';
// import { Checkbox } from 'react-input-checkbox'
import Cookies from 'universal-cookie';

const cookies = new Cookies();








class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 1,
      headerText: props.language.texts.emailScreen.emailHeader,
      tcAccepted: false,
      newsletterClient: false,
      newsletterWeely: false,
      action: '',
      email: "",
      prevActions: {
        instagram: false,
        facebook: false,
        google: false,
        custom: false
      },
      prompt: props.language.texts.emailScreen.errorPromptTC,
      promptOpacity: 0,
      requestPending: false,
      questions: [],
      answers:[],
      answeredQ1: false,
      answeredQ2: false,
      comment: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitEmail = this.submitEmail.bind(this);
    this.toggleTC = this.toggleTC.bind(this);
    this.openAction = this.openAction.bind(this);
    this.createSpin = this.createSpin.bind(this);
    this.setAnswerValue = this.setAnswerValue.bind(this);
    this.displayError = this.displayError.bind(this);
  }

  componentDidMount(){

    if(cookies.get('email')!==null && typeof cookies.get('email')!== undefined) {
          this.setState({
            email: cookies.get('email')
          })
      }

    // let prevActions = this.state.prevActions;
    // if(cookies.get('instagram' + this.props.wheelId)!==null && typeof cookies.get('instagram' + this.props.wheelId)!== undefined) {
    //     prevActions.instagram = cookies.get('instagram' + this.props.wheelId)
    // }
    // if(cookies.get('facebook' + this.props.wheelId)!==null && typeof cookies.get('facebook' + this.props.wheelId)!== undefined) {
    //     prevActions.facebook = cookies.get('facebook' + this.props.wheelId)
    // }
    // if(cookies.get('google' + this.props.wheelId)!==null && typeof cookies.get('google' + this.props.wheelId)!== undefined) {
    //     prevActions.google = cookies.get('google' + this.props.wheelId)
    // }
    // if(cookies.get('custom' + this.props.wheelId)!==null && typeof cookies.get('custom' + this.props.wheelId)!== undefined) {
    //     prevActions.custom = cookies.get('custom' + this.props.wheelId)
    // }
    //uncommnet to set cookies
    // this.setState({
    //   prevActions: prevActions
    // })

    if(typeof this.props.questions !== "undefined" && this.props.questions.length > 0){
        let questionsToAdd = [];
        let questionsCopy = this.props.questions;
        let i = Math.floor(Math.random() * (questionsCopy.length ));
        questionsToAdd.push(questionsCopy[i]);
        questionsCopy.splice(i, 1);
        if(questionsCopy.length > 0){
          let j = Math.floor(Math.random() * (questionsCopy.length ));
          questionsToAdd.push(questionsCopy[j]);
        }
        let answers = [];
        let questions = [];
        questionsToAdd.forEach((question) => {
          questions.push(question);
          answers.push({questionId: question._id, type: question.type});
        })
        this.setState({
          questions: questions,
          answers: answers
        })
    }
  }

  displayError(message){
    this.setState({
      prompt: message,
      promptOpacity: 1
    })
    setTimeout( () => {
      this.setState({
        promptOpacity: 0
      })
    }, 3500);
  }


  openAction(action){
    if(action !== 'question'){
      this.setState({headerText: this.props.language.texts.actionScreen.actionHeaderAfter1 + this.props.wheel.name + this.props.language.texts.actionScreen.actionHeaderAfter2});
    }
    Analytics.pageView("action-clicked");
    this.setState({actionTimeStamp: new Date().getTime()});

    switch (action) {
      case 'facebook':
        Analytics.action("facebook-clicked");
        cookies.set("facebook" + this.props.wheelId, true, {
          path: '/',
          maxAge: 99999999,
        });
        let winFb = window.open(this.props.wheel.facebook_url, '_blank');
        this.setState({action: 'facebook'})
        winFb.focus();
        break;
      case 'instagram':
        Analytics.action("instagram-clicked");
        cookies.set("instagram" + this.props.wheelId, true, {
          path: '/',
          maxAge: 99999999,
        });
        let winIg = window.open(this.props.wheel.instagram_url, '_blank');
        this.setState({action: 'instagram'})
        winIg.focus();
      break;
      case 'google':
        Analytics.action("google-clicked");
        cookies.set("google" + this.props.wheelId, true, {
          path: '/',
          maxAge: 99999999,
      });
        let winGg = window.open(this.props.wheel.google_url, '_blank');
        winGg.focus();
        this.setState({action: 'google'})
        break;
      case 'question':
        Analytics.action("question-clicked");

        this.setState({ progress: 5});
        setTimeout( () => {
          this.setState({ action: "question", progress: 6  , headerText: this.props.language.texts.questionScreen.questionHeader});
        }, 500);
        break;
      case 'custom':
        Analytics.action("custom-clicked");
        cookies.set("custom" + this.props.wheelId, true, {
          path: '/',
          maxAge: 99999999,
      });
        let winCus = window.open(this.props.wheel.custom_action_url, '_blank');
        winCus.focus();
        this.setState({action: 'custom'})
          break;
    default:
      break;
    }
  }

  async submitEmail(){
    if(typeof this.state.email === 'undefined' || this.state.email === ""){
      Analytics.exception("email-invalid-format");

      this.displayError(this.props.language.texts.emailScreen.errorPromptEmail)

      return;
    }
    let emailStr = this.state.email;
    let full_test = (emailStr.split('@').length === 2) && (emailStr.split('@')[1].split('.').length >= 2)
    if(typeof this.state.email === 'undefined' || !full_test){
      Analytics.exception("email-invalid-format");
      this.displayError(this.props.language.texts.emailScreen.errorPromptEmail)
      return;
    }
    if(!this.state.tcAccepted){
      Analytics.exception("email-TC-not-accepted");

      this.displayError(this.props.language.texts.emailScreen.errorPromptTC);
      return;
    }
    if(!this.state.requestPending){
      try {
        Analytics.pageView("pending-email-registration");
        let currentTime = new Date().getTime();

        this.setState({requestPending: true});
        let url = this.props.backEndUrl + '/users';
        let email = this.state.email;
        email = email.replace(/\s+/g, '').toLowerCase();
        let rawResponse = await fetch(url, {
          method: 'post',
          body: JSON.stringify({
            email: email,
            newsletterClient: this.state.newsletterClient,
            newsletterWeely: this.state.newsletterWeely,
            wheelId: this.props.wheel._id
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        let response = await rawResponse.json();
        Analytics.timing('email-register', new Date().getTime() - currentTime);

        this.setState({requestPending: false});
        if(response.success){
          this.props.setEmail(this.state.email);
          cookies.set("email", this.state.email, {
            path: '/',
            maxAge: 99999999,
        });
          this.setState({
            progress: 2,
          })
          this.props.setUserId(response.data.user._id);
          setTimeout( () => {
            Analytics.pageView("action-page");
            this.setState({ progress: 3 , headerText:  this.props.language.texts.actionScreen.actionHeaderAfter1 + this.props.wheel.name + this.props.language.texts.actionScreen.actionHeaderAfter2});

          }, 500);
          setTimeout( () => {
            this.setState({ progress: 4 });
          }, 500);
        } else{
          Analytics.exception("email-response-error");
          console.log("Here")
          console.log(response)
          this.displayError(response.message)
        }
      } catch(err) {
        console.log("tHere")

        this.displayError(err.message)

        this.setState({requestPending: false});
      }
    }
  }


    async createSpin(){
      if(!this.state.requestPending){
        Analytics.timing(this.state.action + '-timing', new Date().getTime() - this.state.actionTimeStamp);
        Analytics.action(this.state.action + '-complete');
        Analytics.pageView("start-spin");
        try {
          let currentTime = new Date().getTime();
          this.setState({requestPending: true});
          let url = this.props.backEndUrl + '/spin/' +this.props.wheel._id;
          let rawResponse = await fetch(url, {
            method: 'post',
            body: JSON.stringify({
              userId: this.props.userId,
              action: this.state.action,
              answers: this.state.answers,
              commentText: this.state.comment,
              lostOutcome: this.props.language.texts.homeScreen.wheelLost
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          });

          let response = await rawResponse.json();
          Analytics.timing('create-spin', new Date().getTime() - currentTime);

          this.setState({requestPending: false});
          if(response.success){
            for(let i = 0; i < this.props.prizes.length; i++){
              if(this.props.prizes[i] === response.data.spin.name){

                this.props.setOutcome(i + 1, !response.data.spin.is_lost, response.data.spin._id)
              }
            }
            this.props.fadeOutOverlay();
          } else{
            this.displayError(response.message);
          }

        } catch(err) {
          this.setState({requestPending: false});
          this.displayError(err.message)
        }
      }
    }

 toggleTC(){
   this.setState({
     tcAccepted: !this.state.tcAccepted
   })
 }

 handleInputChange(event) {
   const target = event.target;
   const value = target.type === 'checkbox' ? target.checked : target.value;
   const name = target.name;
   this.setState({
     [name]: value
   });
 }

  setAnswerValue(i, value){
    if(i === 0){
      this.setState({
        answeredQ1: true,
        answeredQ2: this.state.questions.length === 1 ? true : false
      })
    } else {
      this.setState({
        answeredQ2: true
      })
    }
    let answers = this.state.answers;
    if(typeof value === "string"){  //TAG
      if(typeof answers[i].value === "undefined"){
        answers[i].value = [];
      }

      if(!answers[i].value.includes(value) ){
        console.log("includes")
        answers[i].value.push(value);
      }else{
        answers[i].value.splice(answers[i].value.indexOf(value), 1);
        if(answers[i].value.length === 0){
          if(i === 0){
            this.setState({
              answeredQ1: false
            })
          } else {
            this.setState({
              answeredQ2: false
            })
          }
        }
        
      }
      this.setState({
        answers: answers
      })
    }else{ //SMILEY
      answers[i].value = value;
      this.setState({
        answers: answers
      })
      let hasAnswered = true;
      this.state.answers.forEach((answer)=>{
        if(typeof answer.value === "undefined"){
          hasAnswered = false;
        }
      })
      if(hasAnswered){
        this.setState({
          action: "question"
        })
      }
    }
  }

  render () {
    let registerClasses = "";
    let smsContentClasses = "";
    let socialMediaContentClasses  = "";
    switch (this.state.progress) {
      case 1:
        registerClasses = 'register';
        smsContentClasses ='smsContainer'
        break;
      case 2:
        registerClasses = 'register socialMedia';
        smsContentClasses ='smsContainer after'
        break;

      case 3:
         registerClasses = 'register socialMedia';
         socialMediaContentClasses = 'socialMediaContainer'
         smsContentClasses ='smsContainer after'

         break;
      case 4:
         registerClasses = 'register socialMedia';
         socialMediaContentClasses = 'socialMediaContainer fadeIn'
         break;
      case 5:
        registerClasses = 'register questions';
        socialMediaContentClasses = 'socialMediaContainer fadeOut'
        break;
      case 6:
        registerClasses = 'register questions';
        socialMediaContentClasses = 'socialMediaContainer fadeIn'
        break;
      default:
        break;


    }


    return (
          <div className={registerClasses}>
            {
              this.state.progress < 3 
                ?
                 <div className={smsContentClasses}>
                  <div  style={{color: this.props.wheel.styling.header_color}} className="registerHeader">
                    {this.state.headerText}
                  </div>
                  <div className="email">
                    <div className="emailLabel">E-mail:</div>
                    <input type="email" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="Veuillez saisir votre email" className="emailInput" style={{borderColor: this.props.wheel.styling.header_color}}/>
                  </div>
                  <div className="tcAcceptedContainer">
                    <div  className="tcAccepted rules">
                      <input name="tcAccepted" type='checkbox' value={this.state.tcAccepted} onChange={this.handleInputChange} />
                      <div className="checkboxLabel">
                        {this.props.language.texts.emailScreen.termsAndConditions1}
                        <a className="hyperLink" href={this.props.language.links.tc} target="_blank" rel="noreferrer">
                        {this.props.language.texts.emailScreen.termsAndConditions2}
                        </a>
                      </div>
                    </div>
                    <div  className="tcAccepted rules">
                      <input name="newsletterClient" type='checkbox' value={this.state.newsletterClient} onChange={this.handleInputChange} />
                      <div className="checkboxLabel">
                      {this.props.language.texts.emailScreen.subscribeClient} {this.props.wheel.name}
                      </div>
                    </div>
                    <div  className="tcAccepted rules">
                      <input name="newsletterWeely" type='checkbox' value={this.state.newsletterWeely} onChange={this.handleInputChange} />
                      <div className="checkboxLabel">
                      {this.props.language.texts.emailScreen.subscribeWeely}
                      </div>
                    </div>
                  </div>
                  <div className="submitEmailContainer">
                    <ButtonMain  onPress={this.submitEmail} title={this.props.language.texts.emailScreen.button} width={'65vw'} height={'6.5vh'} borderRadius={'10px'} background={"#fff"} border={("0.2vh solid" + this.props.wheel.styling.button_color)} borderColor={this.props.wheel.styling.button_color} hasShadow={false} fontColor={this.props.wheel.styling.button_color}/>
                  </div>
                  <div style={{opacity: this.state.promptOpacity}} className="prompt">
                    {this.state.prompt}
                  </div>
                </div>
                :

                <div className={socialMediaContentClasses}>
                  <div style={{color: this.props.wheel.styling.header_color}} className="registerHeader">
                    {this.state.headerText}
                  </div>
                  {this.state.progress > 5 ?
                      <div>
                        <Questions language={this.props.language} styling={this.props.wheel.styling} questions={this.state.questions} answers={this.state.answers} comment={this.state.comment} setAnswerValue={this.setAnswerValue} handleInputChange={this.handleInputChange}/>
                        <div className="spinWheelQuestionsButtonContainer">
                          <ButtonMain  onPress={this.createSpin} title={this.props.language.texts.actionScreen.spinButton} width={'70vw'} height={'6vh'} borderRadius={'10px'} background={this.props.wheel.styling.button_color} border={"none"} hasShadow={true} shadow={'0 4px 8px 0 rgba(0,0,0,0.2)'} fontColor={"#fff"} disabled={this.state.answeredQ1 && this.state.answeredQ2  ? false : true}/>
                        </div>
                      </div>
                    :
                    <div>
                      <div className={"socialMediaText"}>{this.props.language.texts.actionScreen.subHeader}</div>
                      {
                        typeof this.props.wheel.instagram_url !== "undefined" && this.props.wheel.instagram_url !== "" ?
                          <div className="socialMediaLinkContainer">
                            <ButtonMain  onPress={() => {this.openAction('instagram')}} title={this.props.language.texts.actionScreen.instagram} width={'70vw'} height={'6vh'} borderRadius={'3vh'} background={"linear-gradient(-234deg, #793BC0 0%, #E93B69 44%, #FFC24C 100%"} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'normal'}  disabled={(this.state.action === 'instagram' || this.state.action === '') &&  !this.state.prevActions.instagram  ? false : true }/>
                            {this.state.action === 'instagram' || this.state.prevActions.instagram ? <img className="check" src={check} alt="checkBox"/> : ''}
                          </div>
                        : ""
                      }
                      {
                        typeof this.props.wheel.google_url !== "undefined" && this.props.wheel.google_url !== "" ?
                          <div className="socialMediaLinkContainer">
                            <ButtonMain onPress={() => {this.openAction('google')}} title={this.props.language.texts.actionScreen.google} width={'70vw'} height={'6vh'} borderRadius={'3vh'} background={"#E13F2A"} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'normal'} disabled={(this.state.action === 'google' || this.state.action === '') &&  !this.state.prevActions.google ? false : true }/>
                            {this.state.action === 'google' || this.state.prevActions.google ? <img className="check" src={check} alt="checkBox"/> : ''}
                          </div>
                        : ""
                      }
                      {
                        typeof this.props.wheel.facebook_url !== "undefined" && this.props.wheel.facebook_url !== ""?
                          <div className="socialMediaLinkContainer">
                            <ButtonMain  onPress={() => {this.openAction('facebook')}} title={this.props.language.texts.actionScreen.facebook} width={'70vw'} height={'6vh'} borderRadius={'3vh'} background={"#4867AA"} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'normal'}  disabled={(this.state.action === 'facebook' || this.state.action === '') &&  !this.state.prevActions.facebook ? false : true }/>
                            {this.state.action === 'facebook' || this.state.prevActions.facebook ? <img className="check" src={check} alt="checkBox"/> : ''}
                          </div>
                        : ""
                      }
                      {
                        typeof this.props.wheel.custom_action_url !== "undefined" && typeof this.props.wheel.custom_action_text !== "undefined" &&  this.props.wheel.custom_action_url !== "" &&  this.props.wheel.custom_action_text !== ""?
                          <div className="socialMediaLinkContainer">
                            <ButtonMain  onPress={() => {this.openAction('custom')}} title={this.props.wheel.custom_action_text} width={'70vw'} height={'6vh'} borderRadius={'3vh'} background={this.props.wheel.styling.button_color} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'normal'}  disabled={(this.state.action === 'custom' || this.state.action === '') &&  !this.state.prevActions.custom ? false : true }/>
                            {this.state.action === 'custom' || this.state.prevActions.custom ? <img className="check" src={check} alt="checkBox"/> : ''}
                          </div>
                        : ""
                      }
                      {
                        typeof this.props.questions !== "undefined" && this.props.questions !== "" && this.props.questions.length !== 0 ?
                          <div className="socialMediaLinkContainer">
                            <ButtonMain  onPress={() => {this.openAction('question')}} title={this.props.language.texts.actionScreen.question} width={'70vw'} height={'6vh'} borderRadius={'3vh'} background={"#72B3FF"} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'normal'} disabled={this.state.action === 'question' || this.state.action === '' ? false : true }/>
                            {this.state.action === 'question'? <img className="check" src={check} alt="checkBox"/> : ''}
                          </div>
                        : ""
                      }
                      <div className="spinWheelButtonContainer">
                        <ButtonMain  onPress={this.createSpin} title={this.props.language.texts.actionScreen.spinButton} width={'70vw'} height={'6vh'} borderRadius={'10px'} background={this.props.wheel.styling.button_color} border={"none"} hasShadow={true} shadow={'0 4px 12px 0 rgba(0,0,0,0.2)'} fontColor={"#fff"} disabled={this.state.action === ''? true : false}/>
                      </div>
                    </div>
                  }
                  
                </div>
            }

          </div>
    )
  }
}
export default Register;
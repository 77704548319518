const englishEU = {
    dateFormat: "dd/mm/yy",
    units: {
        day: "day",
        days: "days",
        hour: "hour",
        hours: "hours",
        minutes: "minutes"
    },
    links:{
        tc: "https://weely.io/tc-en/",
        rules: "https://weely.io/rules-en/"
    },
    texts: {
        homeScreen:{
            title: "Try your luck, spin the wheel, and win a prize from ",
            spinButton: "Spin Wheel",
            rules: "Rules of the game",
            wheelLost: "Lost"
        },
        emailScreen:{
            emailHeader: "You will receive your prize by email",
            termsAndConditions1: "I accept the",
            termsAndConditions2: "Terms and Conditions",
            subscribeClient: "I'd like to stay up to date with news and promotions from ",
            subscribeWeely: "I'd like to stay up to date with promotions from Weely",
            errorPromptTC: "Please accept the terms and conditions",
            errorPromptEmail: "Please enter a valid email",
            button: "Confirm"
        },
        actionScreen: {
            actionHeader1: "Before participating, ",
            actionHeader2: " needs your support!",
            subHeader: "Choose one of the following actions. This should only take you a few seconds",
            instagram: "Follow on Instagram",
            google: "Leave a review on Google",
            facebook: "Like on Facebook",
            question: "Answer a question",
            spinButton: "Spin Wheel",
            actionHeaderAfter1: "",
            actionHeaderAfter2: " thanks you for your support!"
        },
        questionScreen: {
            questionHeader: "Let us know what you think",
            addComment: "Add a comment (optional)"
        },
        losingScreen: {
            header: "Tough Luck!",
            subHeader: "Try again next time!",
            quotes: ["“The greatest glory in living lies not in never falling, but in rising every time we fall”", "“Success is not final, failure is not fatal: it is the courage to continue that counts”", "“Life is like Sanskrit read to a pony”", "“The one charm about the past is that it is the past”"],
            followUs: "Had fun? Follow us on instagram or check out our website!"
        },
        winningScreen: {
            header: "Congratulations!",
            subHeader: "You should receive your prize within a few minutes at the following email:",
            NB1: "Please Note: ",
            NB2: "if you can't find the email, check your spam or promotions folder",
            followUs: "Had fun? Follow us on instagram or check out our website!"
        },
        displayPrizeScreen: {
            header: "Your prize from ",
            expiryDate: "Valid until the "
        },
        validatePrizeScreen: {
            headerValid: "Valid Code",
            headerInvalid: "Invalid Code",
            errorTooEarly1: "Your prize is only valid ",
            errorTooEarly2: " after spinning the wheel",
            errorClaimed: "Your prize has already been claimed",
            errorExpired: "Your prize has expired",
            subHeader: "Validate prize?",
            validateButton: "Validate",
            codeValidated: "Code Validated!"
        }
    }
}

export default englishEU;
import React, { Component } from 'react';
import Div100vh from 'react-div-100vh';
import {isMobile, isMobileSafari, isChrome} from 'react-device-detect';
import Analytics from './components/AnalyticsTracking.js';
import * as texts from './texts';



import Wheel from './components/Wheel.js';

import ButtonMain from './components/ButtonMain.js';
import ClaimPrize from './components/ClaimPrize.js';
import DisplayPrize from './components/DisplayPrize.js';

import Overlay from './components/Overlay.js';
import Confetti from './components/Confetti.js';
import FinalScreen from './components/FinalScreen.js';



import loader from './assets/loading.gif'
import './App.css';

const backendUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : process.env.REACT_APP_API_BASE_URL
//http://172.20.10.5:3001
//http://localhost:3001

//5fbc3408cfd10f2f68c3523b

let search = window.location.search;
let params = new URLSearchParams(search);
const wheelId = params.get('id');
let displayQr = false;
let qrId = "";
if(wheelId == null){
  qrId = params.get('qrId');
  displayQr = true;
}
let displayClaim = false;
let prizeId = "";
if(qrId == null){
  displayQr = false;
  displayClaim = true;
  prizeId = params.get('prizeId');
}
class App extends Component {
  constructor() {
    super();
    this.state = {
      backEndUrl: backendUrl,
      progress: 0,
      rotate: false,
      win: false, //false
      wheelId: wheelId,
      headerText1: "Tentez votre chance, et gagnez un prix chez",
      headerText2: "",
      headerText3: ".",
      loaded: false,
      prizes: [],
      outcome: 0, //0
      spinId: '',
      userId: '',
      displayQr: displayQr,
      qrId: qrId,
      displayClaim: displayClaim,
      prizeId: prizeId,
      isValid: false,
      errMessage: "",
      prizeValidated: false,
      buttonActive: true,
      overlayActive: false,
      email: "",
      requestPending: false,
      isHuawei: false,
      questions: []
    };
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.disableOverlay = this.disableOverlay.bind(this);
    this.setOutcome = this.setOutcome.bind(this);
    this.setEmail = this.setEmail.bind(this);

    this.setUserId = this.setUserId.bind(this);
    this.validatePrize = this.validatePrize.bind(this);
    this.reinitializeHomeScreen = this.reinitializeHomeScreen.bind(this);
    this.audio = new Audio("https://docs.google.com/uc?export=download&id=1aRvU5fHf0-HTTC1tD2yESGUL8Jt4WyNc");
    this.audio.load();
  }



  async componentDidMount(){

    //HUAWEI DETECTION
    const huaweiPhones = [
      "AGS2-W09", "AGS2-W09HN", "ALP-AL00", "ALP-L09", "ALP-L29", "ANE-AL00", "ANE-LX1", "BAH-W09", "BAH2-AL10", "BAH2-W09", "BKL-L09", "BLA-A09", "BLA-AL00", "BLA-L09", "BLA-L29", "CLT-AL00", "CLT-AL01", "CLT-L04", "CLT-L09", "CLT-L29", "CMR-AL09", "CMR-W09", "CMR-W19", "COL-AL10", "COL-L29", "CPN-W09", "D-02K", "ELE-L04", "ELE-L09", "ELE-L29", "ELE-TL00", "EML-L09", "EML-L29", "EVR-L29", "EVR-N29", "FIG-AL00", "FIG-LX1", "HMA-L09", "HMA-L29", "HRY-AL00", "HRY-AL00A", "HRY-AL00T", "HRY-AL00TA", "HRY-LX1", "HRY-LX1MEB", "HRY-LX1T", "HRY-LX2", "HW-02L", "HWI-AL00", "HWI-TL00", "HWV32", "HWV33", "JDN2-W09", "JDN2-W09HN", "JSN-AL00A", "JSN-L21", "JSN-L22", "JSN-L23", "JSN-L42", "JSN-TL00", "LEO-BX9", "LLD-AL00", "LLD-AL10", "LYA-AL00", "LYA-AL10", "LYA-L09", "LYA-L29", "MAR-AL00", "MAR-LX1A", "MAR-LX1B", "MAR-LX1M", "MAR-LX2", "MAR-LX2B", "MAR-LX2J", "MAR-LX3A", "MAR-LX3AM", "MAR-LX3BM", "MAR-TL00", "MRD-LX1", "NEXUS 6P", "PCT-AL10", "PCT-L29", "POT-AL00", "POT-AL00A", "POT-AL10", "POT-LX1", "POT-LX1AF", "POT-LX1T", "POT-LX2J", "POT-LX3", "SCM-W09", "SHT-AL09", "SHT-W09", "SNE-LX1", "SNE-LX2", "SNE-LX3", "STK-L21", "STK-L22", "STK-LX1", "STK-LX3", "STK-TL00", "TNY-TL00", "VCE-L22", "VKY-L09", "VOG-AL10", "VOG-L04", "VOG-L09", "VOG-L29", "YAL-AL00", "YAL-AL10", "YAL-L21", "YAL-L41"
    ] 
    const str = window.navigator.userAgent;
    huaweiPhones.forEach((modelName)=>{
      if(str.includes(modelName)){
        this.setState({isHuawei: true});
      }
    })

    // alert(browserName);
    
    Analytics.initGA("UA-163135735-4"); //GoogleAnalytics connect replace to process.env.GA_ID when set up
    Analytics.pageView("landing-loading");
    if (!this.state.displayQr && !this.state.displayClaim){
      if(isMobile && isMobileSafari){
        this.setState({
          browser: "mobileSafari"
        })
      } else if (isMobile && isChrome){
        this.setState({
          browser: "mobileChrome"
        })
      }

      try {
        let url = this.state.backEndUrl + '/wheel/qr/' + this.state.wheelId;
        let currentTime = new Date().getTime();
        let rawResponse = await fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        let response = await rawResponse.json();
        Analytics.timing('get-wheel-landing', new Date().getTime() - currentTime);
        if(response.success){
          let language = {};
          switch(response.data.language){
            case "french":
              language = texts.french;
              break;
            case "german":
              language = texts.german;
              break;
            case "englishEU":
              language = texts.englishEU;
              break;
            default:
              language = texts.englishEU;
              break;
          }

          let questions = response.data.questions.slice();
          this.setState({
            wheel: response.data,
            questions: questions,
            loaded: true,
            headerText2: response.data.name,
            language: language
          })
          this.generatePrizeArray(response.data.prizes)
          Analytics.pageView("home-loaded");

        } else{
          Analytics.exception("get-wheel");
          alert("Oups, une erreur est survenue pendant le téléchargement de la roue" );
        }

      } catch(err) {
        Analytics.exception("get-wheel"); 

        alert("Oups, une erreur est survenue: " + err.message);
      }
    } else if(this.state.displayQr){
      try {
        let url = this.state.backEndUrl + '/spin/' + this.state.qrId;
        let rawResponse = await fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        let response = await rawResponse.json();
        if(response.success){
          this.setState({
            prizeExpiryDate: response.data.expiryDate,
            prizeInfo: response.data,
          })
          let url2 = this.state.backEndUrl + '/wheel/' + response.data.spin._wheel._id;
          let rawResponse2 = await fetch(url2, {
            method: 'get',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          let response2 = await rawResponse2.json();
          if(response2.success){
            let language = {};
            switch(response2.data.language){
              case "french":
                language = texts.french;
                break;
              case "german":
                language = texts.german;
                break;
              case "englishEU":
                language = texts.englishEU;
                break;
              default:
                language = texts.englishEU;
                break;
            }
            this.setState({
              wheel: response2.data,
              loaded: true,
              language: language
            })
          } else {
            alert("Oups, une erreur est survenue: "+ response2.message);
          }
        } else{
          Analytics.exception("display-QR");

          alert("Oups, une erreur est survenue: "+ response.message);
        }
      } catch(err) {
        Analytics.exception("display-QR");
        alert("Oups, une erreur est survenue: "+ err.message);
      }
    } else if(this.state.displayClaim){
      try {
        let url = this.state.backEndUrl + '/spin/' + this.state.prizeId;
        let rawResponse = await fetch(url, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        let expiryDate = "";

        let response = await rawResponse.json();
        if(response.success){
          let url2 = this.state.backEndUrl + '/wheel/' + response.data.spin._wheel._id;
          let rawResponse2 = await fetch(url2, {
            method: 'get',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          let response2 = await rawResponse2.json();
          if(response2.success){
            let language = {};
            switch(response2.data.language){
              case "french":
                language = texts.french;
                break;
              case "german":
                language = texts.german;
                break;
              case "englishEU":
                language = texts.englishEU;
                break;
              default:
                language = texts.englishEU;
                break;
            }
            console.log(response.data)
            if(typeof response.data.error === "undefined"){
              let dates = response.data.expiryDate.split("-");
              expiryDate = dates[2] + "-" + dates[1] + "-" + dates[0];
            }
            
            this.setState({
              wheel: response2.data,
              language: language
            })
          }
        }
        if(typeof response.data.error !== "undefined" && response.data.error !== ""){
          let errMessage = "";
          if(response.data.error === "already claimed"){
            errMessage = this.state.language.texts.validatePrizeScreen.errorClaimed;
          } 
          if(response.data.error === "expired"){
            errMessage = this.state.language.texts.validatePrizeScreen.errorExpired;
          } 

          this.setState({
            prizeExpiryDate: expiryDate,
            prizeInfo: response.data,
            loaded: true,
            isValid: false,
            errMessage: errMessage
          })
          return;
        }
        if(response.success && response.data.notYetValid){
          Analytics.exception("display-QR-not-yet-valid");

          console.log(this.state.wheel.time_to_claim)
          let timeToClaim = this.state.wheel.time_to_claim;
          let unit = this.state.wheel.time_to_claim === 1 ? this.state.language.units.day : this.state.language.units.days;
          if(this.state.wheel.time_to_claim < 1){
            timeToClaim = Math.round(timeToClaim * 24 * 10) / 10;
            if (timeToClaim === 1){
              unit = this.state.language.units.hour;
            } else {
              unit = this.state.language.units.hours;
            }
          }
          let errMessage = this.state.language.texts.validatePrizeScreen.errorTooEarly1 + timeToClaim + " " + unit + this.state.language.texts.validatePrizeScreen.errorTooEarly2;
          this.setState({
            prizeExpiryDate: expiryDate,
            prizeInfo: response.data,
            loaded: true,
            isValid: false,
            errMessage: errMessage
          })
        } else if(response.success){
          this.setState({
            prizeExpiryDate: expiryDate,
            prizeInfo: response.data,
            loaded: true,
            isValid: true
          })
        } else{
          this.setState({
            loaded: true,
            isValid: false,
            errMessage: response.message
          })
        }
      } catch(err) {
        console.log(err)
        console.log("Code non valable");
      }
    }

  }

  async awardPrize(){
    if(!this.state.requestPending){
      this.setState({
        requestPending: true
      });
      Analytics.pageView("wheel-spun");
      try {
     
        let url = this.state.backEndUrl + '/spin/' + this.state.spinId + '/award';
        let rawResponse = await fetch(url, {
          method: 'put',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        let response = await rawResponse.json();
        if(response.success){
          this.setState({
            requestPending: false
          })
        } else{
          console.log("Oups, une erreur est survenue");
          this.setState({
            requestPending: false
          })
        }
      } catch(err) {
        this.setState({
          requestPending: false
        })
        console.log("Oups, une erreur est survenue: " + err.message);
      }
    }
  }

  async validatePrize(){
    if(!this.state.requestPending){
      this.setState({
        requestPending: true
      })
      try {
        let url = this.state.backEndUrl + '/spin/' + this.state.prizeId + '/claim';
        let rawResponse = await fetch(url, {
          method: 'put',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        let response = await rawResponse.json();
        if(response.success){
          this.setState({
            prizeValidated: true,
            requestPending: false
          })
        } else{
          this.setState({
            requestPending: false
          })
          alert("Oops une erreur est survenue. " + response.message);
        }

      } catch(err) {
        this.setState({
          requestPending: false
        })
        alert("Oups, une erreur est survenue: "+ err.message);
      }
    }
  }



  generatePrizeArray(prizes){
    let prizeArray = [];
    let lostIndexes = [];
    switch (prizes.length){
      case 0:
        lostIndexes = [0,1,2,3,4,5,6,7];
        break;
      case 1:
        lostIndexes = [1,2,3,4,5,6,7];
        break;
      case 2:
        lostIndexes = [1,2,3,5,6,7];
        break;
      case 3:
        lostIndexes = [1,2,4,5,7];
        break;
      case 4:
        lostIndexes = [1,3,5,7];
        break;
      case 5:
        lostIndexes = [2,5,7];
        break;
      case 6:
        lostIndexes = [1,5];
        break;
      case 7:
        lostIndexes = [4];
        break;
      case 8:
        lostIndexes = [];
        break;
      default:
        break;
    }
    let pIndex = 0;
    for (let i = 0; i < 8; i++){
      let isLost = false;
      lostIndexes.forEach(lostIndex => {
        if(lostIndex===i){
          isLost = true;
        }
      });
      if(isLost){
        prizeArray.push(this.state.language.texts.homeScreen.wheelLost)
      }else{
        prizeArray.push(prizes[pIndex].prize_id.name);
        pIndex++;
      }
    }
    this.setState({prizes: prizeArray})
  }

  spinWheel(){
    let audioOffset = 0;
    switch (this.state.outcome){
      case 1:
        audioOffset = 150;
        break;
      case 2:
        audioOffset = 310;
        break;
      case 3:
        audioOffset = 190;
        break;
      case 4:
        audioOffset = 150;
        break;
      case 5:
        audioOffset = 150;
        break;
      case 6:
        audioOffset = 0;
        break;
      case 7:
        audioOffset = 150;
        break;
      case 8:
        audioOffset = 150;
        break;
      default:
        break;
    }
    setTimeout( () => {
      // this.audio.play()

    }, audioOffset);


    setTimeout( () => {
      //if outcome win claim
      this.setState({
        rotate: true
      });
    }, 300);


  }

  setOutcome(outcome, win, spinId){
    this.setState({
      outcome: outcome,
      spinId: spinId
    })
    setTimeout( () => {
      let t = 0;
      if(win){
        t = 2500;
      } else {
        t = 500;
      }
      setTimeout(()=>{
        this.setState({progress: 4});
      },t)
      //if outcome win claim
      this.setState({
        win: win,
        // headerText1: "Dommage...Réessayez lors de votre prochaine visite chez ",
        // headerText2: this.state.wheel.name,
        // headerText3: "!"
      });
      if(win){
        this.setState({
          win: win,
          // headerText1: "Bravo! Votre prix vous a été envoyé par ",
          // headerText2: "e-mail", //this.state.prizes[outcome-1]
          // headerText3: ""
        });
        this.awardPrize()
      }
    }, 10500);
  }

  setUserId(userId){
    this.setState({
      userId: userId
    })
  }

  setEmail(email){
    this.setState({
      email: email
    })
  }

  toggleOverlay(){

    this.setState({
      progress: 1,
      buttonActive: false
    });
    setTimeout( () => {
      Analytics.pageView("email-registration");

      this.setState({ overlayActive: true });
    }, 150);
  }

  reinitializeHomeScreen(){
    this.setState({
      buttonActive: true,
      overlayActive: false
    });
  }

  disableOverlay(){
    this.setState({progress: 3});
    this.spinWheel();
  }

  renderConfetti() {
    if (this.state.win && !this.state.isHuawei) {
      return (<Confetti styling={this.state.wheel.styling}/>);
    } 
  }
  render() {

    if(this.state.loaded && !this.state.displayQr && !this.state.displayClaim){
      return(
        <Div100vh dontResize={true} style={{background: this.state.wheel.styling.color_bg}} className="App">
            {this.renderConfetti()}

            {this.state.progress === 1 ? <Overlay language={this.state.language} setEmail={this.setEmail} wheelId={this.state.wheelId} prizes={this.state.prizes} userId={this.state.userId} setUserId={this.setUserId} setOutcome={this.setOutcome} backEndUrl={this.state.backEndUrl} wheel={this.state.wheel} questions={this.state.questions} disableOverlay={this.disableOverlay} reinitializeHomeScreen={this.reinitializeHomeScreen} overlayActive={this.state.overlayActive}/>  : ""  }
            <div className="headerContainer">
              <div>
                <img alt="logo" id="logo" src={this.state.wheel.image_url}/>
              </div>
              <div className={"title" + (this.state.progress === 4 ? " footerSlideOutAnimation" : "")}>
                {this.state.language.texts.homeScreen.title}<em style={{ fontStyle: "normal", color: this.state.wheel.styling.title_color}}> {this.state.headerText2}</em>{this.state.headerText3}
              </div>
            </div>
            <FinalScreen language={this.state.language} win={this.state.win} email={this.state.email} styling={this.state.wheel.styling} progress={this.state.progress} />
           
            <Wheel browser={this.state.browser} prizes={this.state.prizes} outcome={this.state.outcome} wheel={this.state.wheel} rotate={this.state.rotate} progress={this.state.progress}/>
            <div className={"footerContainer" + (this.state.progress === 4 ? " footerSlideOutAnimation" : "")}>
              <ButtonMain onPress={this.toggleOverlay} title={this.state.language.texts.homeScreen.spinButton} width={'80vw'} height={'7.5vh'} borderRadius={'10px'} background={this.state.wheel.styling.button_color} border={"none"} hasShadow={true} fontColor={"#fff"} fontWeight={'bold'} disabled={this.state.buttonActive? false : true }/>
              <div className="rulesContainer">
                <a className="rules" href={this.state.language.links.rules} target="_blank" rel="noreferrer">{this.state.language.texts.homeScreen.rules}</a>
              </div>
            </div>
            
      </Div100vh>
    )}else if(this.state.loaded && this.state.displayQr){
      return(
        <DisplayPrize language={this.state.language} wheel={this.state.wheel} validatePrize={this.validatePrize} prizeInfo={this.state.prizeInfo} prizeExpiryDate={this.state.prizeExpiryDate} isValid={this.state.isValid} errMessage={this.state.errMessage} prizeValidated={this.state.prizeValidated}/>
      )
    } else if(this.state.loaded && this.state.displayClaim){
      return(
        <ClaimPrize language={this.state.language} validatePrize={this.validatePrize} prizeInfo={this.state.prizeInfo} prizeExpiryDate={this.state.prizeExpiryDate} isValid={this.state.isValid} errMessage={this.state.errMessage} prizeValidated={this.state.prizeValidated}/>
      )
    } else return (
      <Div100vh className="LoadingScreen">
        <img id="loader" src={loader} alt="loader"/>
      </Div100vh>
    )
  };
}


export default App;

import React from 'react';
import ButtonMain from './ButtonMain.js';
import Div100vh from 'react-div-100vh';

import './../App.css';
import logo from '../assets/logo.png'


class ClaimPrize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render () {
    if(!this.props.prizeValidated){
      return (
        <Div100vh className="App">
          <div id="headerContainer">
            <img id="logoWheelyPrize" src={logo} alt="logo"/>

            {
              this.props.isValid?
              <div className="titlePrize">
                <em style={{ fontStyle: "normal", color: 'blue'}}>{this.props.language.texts.validatePrizeScreen.headerValid}</em><br/>{this.props.language.texts.validatePrizeScreen.subHeader}
              </div>
              :
              <div className="titlePrize">
                <em style={{ fontStyle: "normal", color: 'red'}}>{this.props.language.texts.validatePrizeScreen.headerInvalid}</em><br/>{this.props.errMessage}
              </div>
            }

          </div>
          {
            this.props.isValid ?
              <div className="prizeContainer claimContainer">
                <div className="prizeInfoNameClaim">
                  {this.props.prizeInfo.spin.name}
                </div>
                <div className="prizeInfoDescription">
                  {this.props.prizeInfo.spin.description}
                </div>
                <div className="prizeInfoDescription  expiryDate">
                {this.props.language.texts.displayPrizeScreen.expiryDate}<b className="claimExpiryDate">{this.props.prizeExpiryDate}</b>
                </div>
                <div className="prizeInfoId">
                  {this.props.prizeInfo.spin._id}
                </div>
              </div>
            :
            ""
          }
          {
            this.props.isValid?
              <div className="buttonContainerClaim">
                <ButtonMain onPress={this.props.validatePrize} title={this.props.language.texts.validatePrizeScreen.validateButton} width={'85vw'} height={'7vh'} borderRadius={'10px'} background={"#CC44FF"} border={"none"} hasShadow={true} shadow={'0 2px 4px 0 rgba(0,0,0,0.50)'} fontColor={"#fff"} fontWeight={'bold'} disabled={false}/>
              </div>
            :
              ""
          }


        </Div100vh>
      )
    } else {
      return (
        <Div100vh className="App">
          <div id="headerContainer">
            <img id="logoWheelyPrize" src={logo} alt="logo"/>
              <div className="titlePrize">
                <em style={{ fontStyle: "normal", color: '#53d100'}}>{this.props.language.texts.validatePrizeScreen.codeValidated}</em>
              </div>
          </div>
        </Div100vh>
      )
    }

  }
}
export default ClaimPrize;

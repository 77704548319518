import React from 'react';
import './../App.css';
import Div100vh from 'react-div-100vh';
import ButtonMain from './ButtonMain.js';
import instaGlyph from '../assets/instaGlyph.png';
import weelyLogo from '../assets/logo.png';





class FinalScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      gradient1ColorA:"",
      gradient1ColorB: "",
      gradient2ColorA:"",
      gradient2ColorB: "",
      quotes:  props.language.texts.losingScreen.quotes,
      quoteAuthors: ["Nelson Mandela", "Winston Churchill", "Lou Reed", "Oscar Wilde"],
      quoteIndex: 0
    };

  }

  componentDidMount(){
    this.extractGradientColors();
    this.setState({
      quoteIndex: Math.floor(Math.random() * this.state.quotes.length)
    })

  }

  extractGradientColors(){
    let g1 = this.props.styling.color_a;
    let g1Split = g1.split("#");
    let color1A = "#" + g1Split[1].split(" ")[0];
    let color1B = "#" + g1Split[2].split(" ")[0];
    let g2 = this.props.styling.color_b;
    let g2Split = g2.split("#");
    let color2A = "#" + g2Split[1].split(" ")[0];
    let color2B = "#" + g2Split[2].split(" ")[0];
    this.setState({
      gradient1ColorA: color1A,
      gradient1ColorB: color1B,
      gradient2ColorA: color2A,
      gradient2ColorB: color2B
    })

  }


  openLink(link){

    switch (link) {
      case 'instagram':
        // Analytics.action("instagram-clicked");
        let winIg = window.open("https://www.instagram.com/weely_game/", '_blank');
        winIg.focus();
        break;
      case 'site':
        // Analytics.action("google-clicked");

        let winSite = window.open("https://weely.ch", '_blank');
        winSite.focus();
        break;
    
    default:
      break;
    }
  }


  render () {
    //const containerClass = this.props.overlayActive ? 'overlay active' : 'overlay'

    return (
          <Div100vh className="finalScreenContainer">
             <div className={"finalScreenContent" + (this.props.progress === 4 ? " finalScreenAnimation" : "")}>
                <div className="conclusionContainer">
                  <div className="graphicContainer" style={{animation: this.props.progress === 4 ? "spring 3s" : ""}}>
                    {this.props.win ? 
                      <svg width="123px" height="112px" viewBox="0 0 123 112" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <desc>Created with Sketch.</desc>
                        <defs>
                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                                <stop stop-color={this.state.gradient1ColorA} offset="0%"></stop>
                                <stop stop-color={this.state.gradient1ColorB} offset="100%"></stop>
                            </linearGradient>
                            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
                                <stop stop-color={this.state.gradient2ColorA} offset="0%"></stop>
                                <stop stop-color={this.state.gradient2ColorB} offset="100%"></stop>
                            </linearGradient>
                            <rect id="path-3" x="5" y="41" width="112" height="9"></rect>
                            <filter x="-4.9%" y="-38.9%" width="109.8%" height="222.2%" filterUnits="objectBoundingBox" id="filter-4">
                                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                            </filter>
                        </defs>
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="lost-copy" transform="translate(-127.000000, -129.000000)">
                                <g id="Group" transform="translate(127.000000, 129.000000)">
                                    <path d="M36.4885376,19.6518801 L36.4583333,19.6969697 L49.125,19.6969697 L42.7916667,10.2424242 L42.7617557,10.287076 C41.6732213,8.37849919 39.6126999,7.09090909 37.25,7.09090909 C33.7521966,7.09090909 30.9166667,9.91287188 30.9166667,13.3939394 C30.9166667,16.6185345 33.3497653,19.277568 36.4885376,19.6518801 Z M46.7470249,5.51942832 L46.75,5.51515152 L61,26 L36.4583333,26 L36.4583333,25.9941452 C36.3268675,25.9980392 36.1949139,26 36.0625,26 C28.8482805,26 23,20.1797017 23,13 C23,5.82029825 28.8482805,0 36.0625,0 C40.4785219,0 44.3827264,2.18086593 46.7470249,5.51942832 Z" id="Combined-Shape" fill="url(#linearGradient-1)"></path>
                                    <path d="M75.4885376,19.6518801 L75.4583333,19.6969697 L88.125,19.6969697 L81.7916667,10.2424242 L81.7617557,10.287076 C80.6732213,8.37849919 78.6126999,7.09090909 76.25,7.09090909 C72.7521966,7.09090909 69.9166667,9.91287188 69.9166667,13.3939394 C69.9166667,16.6185345 72.3497653,19.277568 75.4885376,19.6518801 Z M85.7470249,5.51942832 L85.75,5.51515152 L100,26 L75.4583333,26 L75.4583333,25.9941452 C75.3268675,25.9980392 75.1949139,26 75.0625,26 C67.8482805,26 62,20.1797017 62,13 C62,5.82029825 67.8482805,0 75.0625,0 C79.4785219,0 83.3827264,2.18086593 85.7470249,5.51942832 Z" id="Combined-Shape-Copy" fill="url(#linearGradient-1)" transform="translate(81.000000, 13.000000) scale(-1, 1) translate(-81.000000, -13.000000) "></path>
                                    <rect id="Rectangle-2" fill="url(#linearGradient-2)" x="5" y="38" width="112" height="74" rx="8"></rect>
                                    <rect id="Rectangle-5" fill="url(#linearGradient-1)" x="45" y="45" width="32" height="67"></rect>
                                    <g id="Rectangle-6" opacity="0.141983696" fill="black" fill-opacity="1">
                                        <use filter="url(#filter-4)" xlinkHref="#path-3"></use>
                                    </g>
                                    <path d="M8,26 L115,26 C119.418278,26 123,29.581722 123,34 L123,46 L0,46 L0,34 C-5.41083001e-16,29.581722 3.581722,26 8,26 Z" id="Rectangle-3" fill="url(#linearGradient-2)"></path>
                                    <rect id="Rectangle-5" fill="url(#linearGradient-1)" x="45" y="26" width="32" height="20"></rect>
                                </g>
                            </g>
                        </g>
                      </svg>

                      :


                      <svg width="105px" height="104px" viewBox="0 0 105 104" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      
                          <desc>Created with Sketch.</desc>
                          <defs></defs>
                          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g id="lost-copy-2" transform="translate(-136.000000, -145.000000)">
                                  <g id="Group" transform="translate(140.000000, 149.000000)">
                                      <ellipse id="Oval-5" stroke={this.props.styling.button_color} stroke-width="7" cx="48.5" cy="48" rx="48.5" ry="48"></ellipse>
                                      <circle id="Oval" fill={this.props.styling.button_color} cx="32.5" cy="41.5" r="6.5"></circle>
                                      <circle id="Oval-Copy" fill={this.props.styling.button_color} cx="63.5" cy="41.5" r="6.5"></circle>
                                      <rect id="Rectangle-10" fill={this.props.styling.button_color} x="36" y="62" width="24" height="4" rx="2"></rect>
                                  </g>
                              </g>
                          </g>
                      </svg>


                    
                  
                  
                    }
                    
                  </div>
                  <div className="conclusionHeader" style={{color: this.props.styling.header_color}}>
                    {this.props.win ? this.props.language.texts.winningScreen.header : this.props.language.texts.losingScreen.header}
                  </div>
                  <div className="conclusionSubHeader">
                    {this.props.win ? this.props.language.texts.winningScreen.subHeader : this.props.language.texts.losingScreen.subHeader}
                  </div>
                    {this.props.win ? 
                      <div className="conclusionEmail" style={{color: this.props.styling.title_color}}>
                        {this.props.email}
                      </div>
                    : 
                      <div className="conclusionQuote">
                        {this.state.quotes[this.state.quoteIndex]}
                      </div>}
                    {this.props.win ? 
                      <div className="conclusionNB">
                        <b className="conclusionBold">{this.props.language.texts.winningScreen.NB1}</b>{this.props.language.texts.winningScreen.NB2}
                      </div>
                    :
                      <div className="conclusionQuoteAuthor">-{this.state.quoteAuthors[this.state.quoteIndex]}</div>
                    }
                    

                </div>
                <div className="followUsContainer">
                    <div className="followUsText">
                      {this.props.language.texts.winningScreen.followUs}
                    </div>
                    <div className="followUsButton">
                      <img className="followInstaIcon" src={instaGlyph} alt="buttonIcon"/>
                      <ButtonMain onPress={()=> {this.openLink("instagram")}} title={""} width={'85vw'} height={'70%'} borderRadius={'25px'} background={"linear-gradient(-234deg, #793BC0 0%, #E93B69 44%, #FFC24C 100%"} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'bold'} disabled={false}/>
                    </div>
                    <div className="followUsButton">
                      <img className="followWeelyIcon" src={weelyLogo} alt="buttonIcon"/>

                      <ButtonMain onPress={()=> {this.openLink("site")}} title={""} width={'85vw'} height={'70%'} borderRadius={'25px'} background={this.props.styling.button_color} border={"none"} hasShadow={false} fontColor={"#fff"} fontWeight={'bold'} disabled={false}/>
                    </div>
                </div>
            </div>
          </Div100vh>
    )
  }
}
export default FinalScreen;

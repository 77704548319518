import ReactGA from "react-ga";

let Analytics = {};

Analytics.initGA = (trackingID) => { 
    ReactGA.initialize(trackingID); 
 }

Analytics.pageView = (page) => {  
    ReactGA.pageview(page); 
}

Analytics.timing = ( action, valueNum) => {
    ReactGA.event({
      category: "timing",
      action: action,
      value: valueNum
    });
};

Analytics.exception = ( action) => {
    ReactGA.event({
      category: "exception",
      action: action
    });
};

Analytics.action = ( action) => {
    ReactGA.event({
      category: "action",
      action: action
    });
};



export default Analytics; 
import React from 'react';
import './../App.css';


class Questions extends React.Component {
  constructor() {
    super();
    this.state = {
      displayCommentInput: false
    }
    this.smileyWidth = "40px";
    this.expandCommentInput = this.expandCommentInput.bind(this);
  }

  expandCommentInput(){
    this.setState({
      displayCommentInput: !this.state.displayCommentInput
    })
  } 

  renderQuestions(){
      return (
        <div className="renderQuestionsContainer">
              {this.props.questions.map((question, i)=>{
                return(
                  <div className="questionContainer" style={{animation: "fadeInQuestion " + (i+1.3)* 1 + "s"}}>
                    <div className="questionNameContainer" style={{color: this.props.styling.header_color}}>
                      {question.name}
                    </div>
                    <div>
                      {question.type === "smiley" ?
                        <div className="smileysContainer">

                          <svg className="smiley" onClick={() => {this.props.setAnswerValue(i, 1)}} width={this.smileyWidth} height={this.smileyWidth} viewBox="0 0 57 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <desc>Created with Sketch.</desc>
                              <defs></defs>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity={this.props.answers[i].value === 1 ? "1": "0.690726902"}>
                                  <g id="Artboard-4" transform="translate(-116.000000, -550.000000)">
                                      <g id="Group" transform="translate(117.000000, 551.000000)">
                                          <circle id="Oval" stroke={this.props.styling.button_color} stroke-width="2" fill={this.props.answers[i].value === 1 ? this.props.styling.button_color : ""} cx="27.5" cy="27.5" r="27.5"></circle>
                                          <path d="M27.5,40.5 C32.1944204,40.5 36,36.6944204 36,32 L19,32 C19,36.6944204 22.8055796,40.5 27.5,40.5 Z" id="Oval-2" fill={this.props.answers[i].value === 1 ? "#ffffff": this.props.styling.button_color} transform="translate(27.500000, 36.250000) scale(1, -1) translate(-27.500000, -36.250000) "></path>
                                          <g id="Group-2" transform="translate(33.000000, 23.000000)" stroke={this.props.answers[i].value === 1 ? "#ffffff": this.props.styling.button_color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                              <polyline id="Line" transform="translate(2.758632, 2.516960) scale(-1, 1) translate(-2.758632, -2.516960) " points="0.953860086 0.864814001 5.41863209 4.16910618 0.098631919 4.16910618"></polyline>
                                          </g>
                                          <g id="Group-2" transform="translate(19.000000, 25.500000) scale(-1, 1) translate(-19.000000, -25.500000) translate(16.000000, 23.000000)" stroke={this.props.answers[i].value === 1 ? "#ffffff": this.props.styling.button_color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                              <polyline id="Line" transform="translate(2.758632, 2.516960) scale(-1, 1) translate(-2.758632, -2.516960) " points="0.953860086 0.864814001 5.41863209 4.16910618 0.098631919 4.16910618"></polyline>
                                          </g>
                                      </g>
                                  </g>
                              </g>
                          </svg>

                          <svg className="smiley" onClick={() => {this.props.setAnswerValue(i, 2)}} width={this.smileyWidth} height={this.smileyWidth} viewBox="0 0 57 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <desc>Created with Sketch.</desc>
                              <defs></defs>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity={this.props.answers[i].value === 2 ? "1": "0.690726902"}>
                                  <g id="Artboard-4" transform="translate(-186.000000, -550.000000)">
                                      <g id="Group" transform="translate(187.000000, 551.000000)">
                                          <circle id="Oval" stroke={this.props.styling.button_color} stroke-width="2" fill={this.props.answers[i].value === 2 ? this.props.styling.button_color : ""} cx="27.5" cy="27.5" r="27.5"></circle>
                                          <path d="M16.7330532,35.2017746 C20.2780532,37.2017746 23.8230533,38.2017746 27.3680534,38.2017746 C30.9130535,38.2017746 34.4580535,37.2017746 38.0030536,35.2017746" id="Line" stroke={this.props.answers[i].value === 2 ? "#ffffff": this.props.styling.button_color} stroke-width="3" stroke-linecap="round" transform="translate(27.368053, 36.701775) scale(1, -1) translate(-27.368053, -36.701775) "></path>
                                          <path d="M15.471445,23.4180759 L19.7546189,24.8097634 C19.9125007,25.1746612 20,25.5771123 20,26 C20,27.6568542 18.6568542,29 17,29 C15.3431458,29 14,27.6568542 14,26 C14,24.9014048 14.5905136,23.9407315 15.471445,23.4180759 Z" id="Combined-Shape" fill={this.props.answers[i].value === 2 ? "#ffffff": this.props.styling.button_color}></path>
                                          <path d="M36.471445,23.4180759 L40.7546189,24.8097634 C40.9125007,25.1746612 41,25.5771123 41,26 C41,27.6568542 39.6568542,29 38,29 C36.3431458,29 35,27.6568542 35,26 C35,24.9014048 35.5905136,23.9407315 36.471445,23.4180759 Z" id="Combined-Shape" fill={this.props.answers[i].value === 2 ? "#ffffff": this.props.styling.button_color} transform="translate(38.000000, 26.209038) scale(-1, 1) translate(-38.000000, -26.209038) "></path>
                                      </g>
                                  </g>
                              </g>
                          </svg>

                          <svg className="smiley" onClick={() => {this.props.setAnswerValue(i, 3)}} width={this.smileyWidth} height={this.smileyWidth} viewBox="0 0 57 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <desc>Created with Sketch.</desc>
                              <defs></defs>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity={this.props.answers[i].value === 3 ? "1": "0.690726902"}>
                                  <g id="Artboard-4" transform="translate(-256.000000, -550.000000)">
                                      <g id="Group" transform="translate(257.000000, 551.000000)">
                                          <circle id="Oval" stroke={this.props.styling.button_color} stroke-width="2" fill={this.props.answers[i].value === 3 ? this.props.styling.button_color : ""} cx="27.5" cy="27.5" r="27.5"></circle>
                                          <path d="M17.7330532,36.2017746 L36.9984711,36.2017746" id="Line" stroke={this.props.answers[i].value === 3 ? "#ffffff": this.props.styling.button_color} stroke-width="3" stroke-linecap="round" transform="translate(27.365762, 36.201775) scale(1, -1) translate(-27.365762, -36.201775) "></path>
                                          <circle id="Oval-3" fill={this.props.answers[i].value === 3 ? "#ffffff": this.props.styling.button_color} cx="17" cy="26" r="3"></circle>
                                          <circle id="Oval-3" fill={this.props.answers[i].value === 3 ? "#ffffff": this.props.styling.button_color} cx="38" cy="26" r="3"></circle>
                                      </g>
                                  </g>
                              </g>
                          </svg>

                          <svg className="smiley" onClick={() => {this.props.setAnswerValue(i, 4)}} width={this.smileyWidth} height={this.smileyWidth} viewBox="0 0 57 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <desc>Created with Sketch.</desc>
                              <defs></defs>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity={this.props.answers[i].value === 4 ? "1": "0.690726902"}>
                                  <g id="Artboard-4" transform="translate(-326.000000, -550.000000)">
                                      <g id="Group" transform="translate(327.000000, 551.000000)">
                                          <circle id="Oval" stroke={this.props.styling.button_color} stroke-width="2" fill={this.props.answers[i].value === 4 ? this.props.styling.button_color : ""} cx="27.5" cy="27.5" r="27.5"></circle>
                                          <path d="M17.7330532,39.2620035 C20.2884731,36.5551842 23.4993761,35.2017746 27.3657621,35.2017746 C31.2321482,35.2017746 34.4430512,36.5551842 36.9984711,39.2620035" id="Line" stroke={this.props.answers[i].value === 4 ? "#ffffff": this.props.styling.button_color} stroke-width="3" stroke-linecap="round" transform="translate(27.365762, 37.231889) scale(1, -1) translate(-27.365762, -37.231889) "></path>
                                          <circle id="Oval-3" fill={this.props.answers[i].value === 4 ? "#ffffff": this.props.styling.button_color} cx="17" cy="26" r="3"></circle>
                                          <circle id="Oval-3" fill={this.props.answers[i].value === 4 ? "#ffffff": this.props.styling.button_color} cx="38" cy="26" r="3"></circle>
                                      </g>
                                  </g>
                              </g>
                          </svg>


                          <svg className="smiley" onClick={() => {this.props.setAnswerValue(i, 5)}} width={this.smileyWidth} height={this.smileyWidth} viewBox="0 0 57 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                              <desc>Created with Sketch.</desc>
                              <defs></defs>
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity={this.props.answers[i].value === 5 ? "1": "0.690726902"}>
                                  <g id="Artboard-4" transform="translate(-396.000000, -550.000000)">
                                      <g id="Group" transform="translate(397.000000, 551.000000)">
                                          <circle id="Oval" stroke={this.props.styling.button_color} stroke-width="2" fill={this.props.answers[i].value === 5 ? this.props.styling.button_color : ""} cx="27.5" cy="27.5" r="27.5"></circle>
                                          <path d="M27.5,42.5 C32.1944204,42.5 36,38.6944204 36,34 L19,34 C19,38.6944204 22.8055796,42.5 27.5,42.5 Z" id="Oval-2" fill={this.props.answers[i].value === 5 ? "#ffffff": this.props.styling.button_color}></path>
                                          <path d="M11.5,26.5 C11.9708233,22.248189 13.9708233,20.1222835 17.5,20.1222835 C21.0291767,20.1222835 23.0291767,22.248189 23.5,26.5" id="Line" stroke={this.props.answers[i].value === 5 ? "#ffffff": this.props.styling.button_color} stroke-width="3" stroke-linecap="square"></path>
                                          <path d="M31.5,26.5 C31.9708233,22.248189 33.9708233,20.1222835 37.5,20.1222835 C41.0291767,20.1222835 43.0291767,22.248189 43.5,26.5" id="Line" stroke={this.props.answers[i].value === 5 ? "#ffffff": this.props.styling.button_color} stroke-width="3" stroke-linecap="square"></path>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                        </div>
                      :
                        <div  className="tagsContainer">
                          {
                            question.tags.map((tag, j)=>{
                              return(
                                <div onClick={() => {this.props.setAnswerValue(i, tag)}} className="tag" style={{
                                    background: typeof this.props.answers[i].value !== "undefined" &&  this.props.answers[i].value.includes(tag) ? this.props.styling.button_color : "",
                                    border: "1px solid " + this.props.styling.button_color,
                                    color: typeof this.props.answers[i].value !== "undefined" &&  this.props.answers[i].value.includes(tag) ? "white" : this.props.styling.button_color
                                    }}>
                                  {tag}
                                </div>
                              )
                            })
                          }
                          
                        </div>
                      }
                    </div>
                </div>
                )
              })}
        </div>
      
      )
    
  }
  render () {

    return(
        <div className="questionsScreenContainer">
          <div className="questionsUnderline" style={{borderTop: "1px solid " + this.props.styling.header_color}}/>
          {this.renderQuestions()}
          <div className="commentContainer">
            <div onClick={this.expandCommentInput} className="addComment" style={{color: this.props.styling.header_color}}>
            {this.state.displayCommentInput ? "- " : "+ "}{this.props.language.texts.questionScreen.addComment}
            </div>
              {this.state.displayCommentInput ? 
                  <textarea rows="3" className="commentInput" name="comment" type="text" value={this.props.comment} onChange={this.props.handleInputChange} style={{border: "1px solid " + this.props.styling.button_color}}/>
              :
              ""
              }
          </div>
          
        </div>
    )
  }
}
export default Questions;

import React from 'react';
import Div100vh from 'react-div-100vh';
import QRCode from 'qrcode.react';

import {isMobile} from 'react-device-detect';

import './../App.css';
import logo from '../assets/logo.png'


class ClaimPrize extends React.Component {


  render () {
    let classPrizeContainer = isMobile ? "prizeContainer" : "prizeContainerDesktop";
    console.log(classPrizeContainer);
    return(
      <Div100vh className="App">
        <div id="headerContainer">
          <img id="logoWheelyPrize" src={logo} alt="logo"/>
          <div className="titlePrize">
            {this.props.language.texts.displayPrizeScreen.header} <em style={{ fontStyle: "normal", color: this.props.wheel.styling.title_color}}> {this.props.wheel.name}</em>.
          </div>
        </div>
        <div className={"prizeContainer"}>

          <QRCode size={194} value={ process.env.REACT_APP_FRONT_END_URL + "/?prizeId=" + this.props.prizeInfo.spin._id} />
          <div style={{color: this.props.wheel.styling.header_color}} className="prizeInfoName">
            {this.props.prizeInfo.spin.name}
          </div>

          <div className="prizeInfoDescription">
            {this.props.prizeInfo.spin.description}
          </div>
          <div className="prizeInfoDescription expiryDate">
          {this.props.language.texts.displayPrizeScreen.expiryDate} {this.props.prizeExpiryDate}
          </div>
          <div style={{color: this.props.wheel.styling.title_color}} className="prizeInfoId">
            {this.props.prizeInfo.spin._id}
          </div>
        </div>
      </Div100vh>
    )
  }
}
export default ClaimPrize;

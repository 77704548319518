import React from 'react';
import './../App.css';


class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prizes: ['Pizza Offerte*', 'Perdu', 'Perdu', 'Apérol Spritz*', 'Perdu', 'Tiramisu', 'Perdu', 'Stylo'],
      styling: {
        colorMain: 'red',
        colorSec: 'white',
        colorSlices: ['linear-gradient(-45deg, #A022FC 0%, #670FF7 100%)','-webkit-linear-gradient(-45deg, #FFDD25 0%, #FEB910 100%)','-webkit-linear-gradient(-45deg, #A022FC 0%, #670FF7 100%)','-webkit-linear-gradient(-45deg, #FFDD25 0%, #FEB910 100%)','-webkit-linear-gradient(-45deg, #A022FC 0%, #670FF7 100%)','-webkit-linear-gradient(-45deg, #FFDD25 0%, #FEB910 100%)', '-webkit-linear-gradient(-45deg, #A022FC 0%, #670FF7 100%)', '-webkit-linear-gradient(-45deg, #FFDD25 0%, #FEB910 100%)'],
        logoUrl: 'https://www.luigia.ch/wp-content/uploads/2017/04/LUIGIA_LOGO_TXT_BORDEAUX_copie.png'
      }
    };
    this.wheelRef = React.createRef();
    if(props.browser === "mobileSafari"){
      this.scale = 'scale(0.9)';
    } else {
      this.scale = 'scale(1)'
    }

  }


  render() {
    return (
      <div style={{transform: this.scale}} className={"wheel" +  (this.props.progress === 4? " wheelSlideOutAnimation" : "")}>
        <div id='left'>
          <svg  className="prizePicker" width="74px" height="71px" viewBox="0 0 74 71" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>Created with Sketch.</desc>
            <defs>
                <path d="M46.199937,18.2991121 C49.4884971,18.2991121 63.6612917,49.1738377 62.6492958,51.1978296 C61.6372999,53.2218214 30.7426104,53.1818939 29.7505783,51.1978296 C28.7585461,49.2137653 42.911377,18.2991121 46.199937,18.2991121 Z" id="path-1"></path>
                <filter x="-110.6%" y="-135.2%" width="321.2%" height="312.2%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="-10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="10.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.186594203 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Triangle-2" transform="translate(46.200888, 35.500000) rotate(270.000000) translate(-46.200888, -35.500000) ">
                    <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                    <use fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#path-1"></use>
                </g>
            </g>
          </svg>
          <div id="mainbox" className={this.props.rotate ? "rotate" + this.props.outcome + " mainbox" : "mainbox"}>

            <div style={{borderColor: this.props.wheel.styling.color_c}} id="circleBack"></div>

            <div style={{color: this.props.wheel.styling.font_color_b}} id="prizeLabel1" class="prizeLabel"><div>{this.props.prizes[0]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_b}} id="prizeLabel2" class="prizeLabel"><div>{this.props.prizes[4]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_b}} id="prizeLabel3" class="prizeLabel"><div>{this.props.prizes[6]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_b}} id="prizeLabel4" class="prizeLabel"><div>{this.props.prizes[2]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_a}} id="prizeLabel5" class="prizeLabel"><div>{this.props.prizes[3]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_a}} id="prizeLabel6" class="prizeLabel"><div>{this.props.prizes[1]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_a}} id="prizeLabel7" class="prizeLabel"><div>{this.props.prizes[7]}</div></div>
            <div style={{color: this.props.wheel.styling.font_color_a}} id="prizeLabel8" class="prizeLabel"><div>{this.props.prizes[5]}</div></div>

            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle1" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle2" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle3" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle4" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle5" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle6" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle7" class="triangle"></div>
            <div style={{borderBottomColor: this.state.styling.colorSec}} id= "triangle8" class="triangle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle1" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle2" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle3" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle4" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle5" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle6" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle7" class="circle"></div>
            <div style={{backgroundColor: this.props.wheel.styling.color_b}} id= "circle8" class="circle"></div>

            <div style={{borderColor: this.state.styling.colorSec}}  id="box" class="box">
              <div class="box1">

                <span style={{background: this.props.wheel.styling.color_b}} class="span1"></span>
                <span style={{background: this.props.wheel.styling.color_b}} class="span2"></span>
                <span style={{background: this.props.wheel.styling.color_b}} class="span3"></span>
                <span style={{background: this.props.wheel.styling.color_b}} class="span4"></span>
              </div>
              <div class="box2">
                <span style={{background: this.props.wheel.styling.color_a}} class="span5"></span>
                <span style={{background: this.props.wheel.styling.color_a}} class="span6"></span>
                <span style={{background: this.props.wheel.styling.color_a}} class="span7"></span>
                <span style={{background: this.props.wheel.styling.color_a}} class="span8"></span>
              </div>
            </div>

            <div style={{background: this.props.wheel.styling.color_c}} class="center" ></div>

          </div>
        </div>
      </div>
    );
  }
}
export default Wheel;

import React from 'react';
import './../App.css';

class ButtonMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false
    };
  }
  toggleTouched = () => {
    if(!this.props.disabled){
      this.props.onPress();
    }

    this.setState( prevState => ({
      touched: !prevState.touched
    }));
    setTimeout( () => {
      this.setState({ touched: false });
    }, 350);

  }

  handleMouseUp = () => {
    // Handle smooth animation when clicking without holding
    setTimeout( () => {
      this.setState({ touched: false });
    }, 150);
  }
  render () {
    const { touched } = this.state;
    const className = touched ? 'buttonContainer touched' : 'buttonContainer';

    return (
      <div style={{height: this.props.height, minHeight: '44px'}} className={className}>
        <button
          className='btn'
          onMouseDown={this.toggleTouched}
          onMouseUp={this.handleMouseUp}
          style={{width: this.props.width, height: "100%", borderRadius: this.props.borderRadius, background: this.props.background, border: this.props.border, boxShadow: this.props.hasShadow&&!this.props.disabled ? this.props.shadow : 'none', color: this.props.fontColor, opacity: this.props.disabled ? 0.5 : 1, fontWeight: this.props.fontWeight}}
        >
          {this.props.title}
        </button>
      </div>
    )
  }
}
export default ButtonMain;

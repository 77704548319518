import React from 'react';
import './../App.css';
import Register from './Register.js';
import Div100vh from 'react-div-100vh'



class Overlay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.fadeOutOverlay = this.fadeOutOverlay.bind(this);
  }


  fadeOutOverlay(){
    this.props.reinitializeHomeScreen();
    this.setState({
      active: false
    })
    setTimeout( () => {
      this.props.disableOverlay();
    }, 500);
  }

   returnHome = () => {
    this.setState({
      active: false
    })
    setTimeout( () => {
      this.props.reinitializeHomeScreen();
    }, 200);
  }

  render () {
    const overlayClass = this.props.overlayActive ? 'overlay active' : 'overlay'

    return (
          <Div100vh className={overlayClass}>
            <Div100vh onClick={this.returnHome} className="overlayBackground"/>
            <Register language={this.props.language} setEmail={this.props.setEmail} wheelId={this.props.wheelId} prizes={this.props.prizes} userId={this.props.userId} setUserId={this.props.setUserId} setOutcome={this.props.setOutcome} wheel={this.props.wheel} questions={this.props.questions.slice()} backEndUrl={this.props.backEndUrl}  fadeOutOverlay={this.fadeOutOverlay}/>
          </Div100vh>
    )
  }
}
export default Overlay;

const french = {
    dateFormat: "dd/mm/yy",
    units: {
        day: "jour",
        days: "jours",
        hour: "heure",
        hours: "heures",
        minutes: "minutes"
    },
    links:{
        tc: "https://weely.io/tc-fr/",
        rules: "https://weely.io/rules-fr/"
    },
    texts: {
        homeScreen:{
            title: "Tentez votre chance, et gagnez un prix chez ",
            spinButton: "Tourner la roue",
            rules: "Règlement du Jeu",
            wheelLost: "Perdu"
        },
        emailScreen:{
            emailHeader: "Vous recevrez votre prix par email",
            termsAndConditions1: "J'accepte les",
            termsAndConditions2: "Termes et Conditions",
            subscribeClient: "Je souhaite recevoir des promotions et news de ",
            subscribeWeely: "Je souhaite recevoir des promotions de Weely",
            errorPromptTC: "Veuillez accepter nos termes et conditions",
            errorPromptEmail: "Veuillez saisir un email valide",
            button: "Valider"
        },
        actionScreen: {
            actionHeader1: "Avant de participer, ",
            actionHeader2: " a besoin de vous!",
            subHeader: "Choisissez une des trois méthodes. Cela prend 2 clicks.",
            instagram: "Suivre sur Instagram",
            google: "Laisser un avis sur Google",
            facebook: "Suivre sur Facebook",
            question: "Répondre à une question",
            spinButton: "Lancer la roue",
            actionHeaderAfter1: "",
            actionHeaderAfter2: " vous remercie de votre soutien!"
        },
        questionScreen: {
            questionHeader: "Votre avis nous intéresse",
            addComment: "Ajouter un commentaire (optionnel)"
        },
        losingScreen: {
            header: "Dommage !",
            subHeader: "Réessayer une prochaine fois",
            quotes: ["“La plus grande gloire n'est pas de ne jamais tomber, mais de se relever à chaque chute”", "“Le succès n'est pas final. L'échec n'est pas fatal. C'est le courage de continuer qui compte”", "“La vie, c’est comme du Sanskrit lu à un poney”", "“Le seul charme du passé, c'est qu'il est le passé”"],
            followUs: "Vous avez aimez Weely ? Suivez-nous sur instagram ou contactez nous sur notre site !"
        },
        winningScreen: {
            header: "Félicitations !",
            subHeader: "Votre prix vous a été envoyé à l'adresse email suivante:",
            NB1: "Remarque: ",
            NB2: "si vous ne trouvez pas de mail, veuillez vérifiez votre boîte spam",
            followUs: "Vous avez aimez Weely ? Suivez-nous sur instagram ou contactez nous sur notre site !"
        },
        displayPrizeScreen: {
            header: "Votre prix chez ",
            expiryDate: "Valable jusqu'au "
        },
        validatePrizeScreen: {
            headerValid: "Code valable",
            headerInvalid: "Code non valable",
            errorTooEarly1: "Votre prix n'est que valable ",
            errorTooEarly2: " après avoir tourné la roue",
            errorClaimed: "Vous avez déjà réclamé votre prix",
            errorExpired: "Vous avez passé la date de validité de votre prix",
            subHeader: "Valider le prix?",
            validateButton: "Valider",
            codeValidated: "Code Validé !"
        }
    }
}

export default french;
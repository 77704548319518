import React from 'react';
 import ReactDOM from 'react-dom'

import './../App.css';
import Div100vh from 'react-div-100vh'
const confetti = require('canvas-confetti');




class Confetti extends React.Component {

  // constructor(){
  //   super();
  //   // this.canvas = React.createRef()  method2 huawei

  // }
  // componentDidMount(){
  //     console.log(this.props.styling.color_a)
  //     const canvas = this.canvas.current;
  //     // let canvas = ReactDOM.findDOMNode(this.refs.canvas);
  //     // let canvas = this.refs.canvas;


  //     let myConfetti = confetti.create(canvas, {
  //       resize: true,
  //       useWorker: true
  //     });
  //     myConfetti({
  //       particleCount: 80,
  //       spread: 120,
  //       angle: 45,
  //       // any other options from the global
  //       // confetti function
  //       ticks: 400,
  //       origin: {
  //         x: 0 - (0.5 - Math.random())/2,
  //         // since they fall down, start a bit higher than random
  //         y: 0.4
  //       },
  //     });
  //   setTimeout( () => {
  //     myConfetti({
  //       particleCount: 80,
  //       spread: 120,
  //       angle: 135,
  //       // any other options from the global
  //       // confetti function
  //       ticks: 400,
  //       origin: {
  //         x: 1 + (0.5 - Math.random())/2,
  //         // since they fall down, start a bit higher than random
  //         y: 0.4
  //       },
  //     });
  //   }, 200);
  //   setTimeout( () => {
  //     myConfetti({
  //       particleCount: 80,
  //       spread: 120,
  //       angle: 90,
  //       // any other options from the global
  //       // confetti function
  //       ticks: 400,
  //       origin: {
  //         x: 0.5 - (0.5 - Math.random())/2,
  //         // since they fall down, start a bit higher than random
  //         y: 1
  //       },
  //     });
  //       // any other options from the global
  //       // confetti function
  //   }, 400);
  //   setTimeout( () => {
  //     myConfetti({
  //       particleCount: 80,
  //       spread: 120,
  //       angle: 45,
  //       // any other options from the global
  //       // confetti function
  //       ticks: 400,
  //       origin: {
  //         x: 0 - (0.5 - Math.random())/2,
  //         // since they fall down, start a bit higher than random
  //         y: 0.4
  //       },
  //     });
  //       // any other options from the global
  //       // confetti function
  //   }, 600);
  //   setTimeout( () => {
  //     myConfetti({
  //       particleCount: 80,
  //       spread: 120,
  //       angle: 135,
  //       // any other options from the global
  //       // confetti function
  //       ticks: 400,
  //       origin: {
  //         x: 1 + (0.5 - Math.random())/2,
  //         // since they fall down, start a bit higher than random
  //         y: 0.4
  //       },
  //     });
  //       // any other options from the global
  //       // confetti function
  //   }, 800);
  //   setTimeout( () => {
  //     myConfetti({
  //       particleCount: 80,
  //       spread: 120,
  //       angle: 90,
  //       // any other options from the global
  //       // confetti function
  //       ticks: 400,
  //       origin: {
  //         x: 0.5 - (0.5 - Math.random())/2,
  //         // since they fall down, start a bit higher than random
  //         y: 1
  //       },
  //     });
  //       // any other options from the global
  //       // confetti function
  //   }, 1000);
  // }

  componentDidMount(){
    console.log(this.props.styling.color_a)
    let canvas = ReactDOM.findDOMNode(this.refs.canvas);

    let myConfetti = confetti.create(canvas, {
      resize: true,
      useWorker: true
    });
    myConfetti({
      particleCount: 80,
      spread: 120,
      angle: 45,
      // any other options from the global
      // confetti function
      ticks: 400,
      origin: {
        x: 0 - (0.5 - Math.random())/2,
        // since they fall down, start a bit higher than random
        y: 0.4
      },
    });
  setTimeout( () => {
    myConfetti({
      particleCount: 80,
      spread: 120,
      angle: 135,
      // any other options from the global
      // confetti function
      ticks: 400,
      origin: {
        x: 1 + (0.5 - Math.random())/2,
        // since they fall down, start a bit higher than random
        y: 0.4
      },
    });
  }, 200);
  setTimeout( () => {
    myConfetti({
      particleCount: 80,
      spread: 120,
      angle: 90,
      // any other options from the global
      // confetti function
      ticks: 400,
      origin: {
        x: 0.5 - (0.5 - Math.random())/2,
        // since they fall down, start a bit higher than random
        y: 1
      },
    });
      // any other options from the global
      // confetti function
  }, 400);
  setTimeout( () => {
    myConfetti({
      particleCount: 80,
      spread: 120,
      angle: 45,
      // any other options from the global
      // confetti function
      ticks: 400,
      origin: {
        x: 0 - (0.5 - Math.random())/2,
        // since they fall down, start a bit higher than random
        y: 0.4
      },
    });
      // any other options from the global
      // confetti function
  }, 600);
  setTimeout( () => {
    myConfetti({
      particleCount: 80,
      spread: 120,
      angle: 135,
      // any other options from the global
      // confetti function
      ticks: 400,
      origin: {
        x: 1 + (0.5 - Math.random())/2,
        // since they fall down, start a bit higher than random
        y: 0.4
      },
    });
      // any other options from the global
      // confetti function
  }, 800);
  setTimeout( () => {
    myConfetti({
      particleCount: 80,
      spread: 120,
      angle: 90,
      // any other options from the global
      // confetti function
      ticks: 400,
      origin: {
        x: 0.5 - (0.5 - Math.random())/2,
        // since they fall down, start a bit higher than random
        y: 1
      },
    });
      // any other options from the global
      // confetti function
  }, 1000);
}






  render () {

    return (
          <Div100vh className={"confettiContainer"}>
            <canvas ref={this.canvas}/>
          </Div100vh>
    )
  }
}
export default Confetti;
